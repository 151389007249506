import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RMPage } from '@/components/RMPage';
import { toast } from '@/components/RMToast/RMToast';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import { BookQuantityFormViewContainer } from '@/modules/book-builder/containers/BookQuantityFormView.container';
import {
  BookReorderHeaderContainer,
  BookReorderHeaderStep,
} from '@/modules/book-builder/containers/BookReorderHeader.container';
import { BookReorderSummaryContainer } from '@/modules/book-builder/containers/BookReorderSummary.container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { getBookReorderFinalizePath, getBookReorderPreviewPath } from '@/modules/routing';
import { captureException } from '@/utils/captureException';

import { Button, Buttons, Content, LeftPanel, RightPanel } from './BookReorderQuantityPage.styles';

export function BookReorderQuantityPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookOrderId = params.bookOrderId ?? '';

  // Services
  const navigate = useNavigate();

  // State
  const { quantityForm } = useBookOrderContext();
  const isQuantityFormValid = useIsFormValid(quantityForm);

  const handlePrevious = useCallback(() => {
    navigate(getBookReorderPreviewPath(projectId, bookOrderId));
  }, [bookOrderId, navigate, projectId]);

  const handleNext = useCallback(async () => {
    try {
      await submitForm(quantityForm, async () => {
        navigate(getBookReorderFinalizePath(projectId, bookOrderId));
      });
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [quantityForm, navigate, projectId, bookOrderId]);

  return (
    <RMPage.Root>
      <BookReorderHeaderContainer
        projectId={projectId}
        bookOrderId={bookOrderId}
        step={BookReorderHeaderStep.Quantity}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <Content>
          <LeftPanel>
            <BookQuantityFormViewContainer projectId={projectId} form={quantityForm} />

            <Buttons>
              <Button background="neutral" autoLoading onClick={handlePrevious}>
                Previous
              </Button>
              <Button background="primary" autoLoading disabled={isQuantityFormValid == false} onClick={handleNext}>
                Next
              </Button>
            </Buttons>
          </LeftPanel>
          <RightPanel>
            <BookReorderSummaryContainer projectId={projectId} bookOrderId={bookOrderId} quantityForm={quantityForm} />
          </RightPanel>
        </Content>
      </RMPage.Content>
    </RMPage.Root>
  );
}
