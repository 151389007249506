import { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate, useParams } from 'react-router-dom';
import { ShippingAddressValidationErrorCode } from '@remento/types/shipping';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import { BookShippingFormOutput } from '@/modules/book-builder/book-shipping.form';
import { BookShippingFormView } from '@/modules/book-builder/components/BookShippingFormView/BookShippingFormView';
import { BookOrderCheckoutReviewContainer } from '@/modules/book-builder/containers/BookOrderCheckoutReview.container';
import {
  BookOrderHeaderContainer,
  BookOrderHeaderStep,
} from '@/modules/book-builder/containers/BookOrderHeader.container';
import { BookOrderSummaryContainer } from '@/modules/book-builder/containers/BookOrderSummary.container';
import { ShippingAddressErrorDialogContainer } from '@/modules/book-builder/containers/ShippingAddressErrorDialog.container';
import { getFormValue, isFormValid, submitForm, useIsFormValid } from '@/modules/form/form';
import { setInputValues } from '@/modules/form/input';
import { getBookOrderQuantityPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useBookQuery } from '@/services/api/book';
import { captureException } from '@/utils/captureException';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { ButtonsWrapper, Content, LeftPanel, RightPanel } from './BookOrderFinalizePage.styles';

interface ShippingAddressErrorState {
  error: ShippingAddressValidationErrorCode | 'UNKNOWN' | null;
  input: BookShippingFormOutput;
  resolvedAddress: BookShippingFormOutput | null;
}

export function BookOrderFinalizePage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookId = params.bookId ?? '';

  // Services
  const { bookService } = useServices();
  const navigate = useNavigate();

  // State
  const { quantityForm, shippingForm } = useBookOrderContext();
  const isShippingFormValid = useIsFormValid(shippingForm);

  const [step, setStep] = useState<'shipping' | 'review'>(() => {
    const quantity = getFormValue(quantityForm);
    if (quantity == null) {
      // The page will be redirected to the previous step in this case.
      return 'shipping';
    }
    return quantity.quantity == 0 ? 'review' : 'shipping';
  });
  const [isReviewed, setIsReviewed] = useState(false);
  const [shippingAddressError, setShippingAddressError] = useState<ShippingAddressErrorState | null>(null);

  // Queries
  const bookQuery = useBookQuery(bookId);

  const handleEdiShipping = useCallback(() => {
    setStep('shipping');
  }, []);

  const handlePrevious = useCallback(() => {
    if (step === 'review') {
      const quantityFormValue = getFormValue(quantityForm);
      if (quantityFormValue?.quantity !== 0) {
        setStep('shipping');
        return;
      }
    }
    navigate(getBookOrderQuantityPath(projectId, bookId));
  }, [bookId, navigate, projectId, quantityForm, step]);

  const handleReview = useCallback(async () => {
    try {
      await submitForm(shippingForm, async (address) => {
        const validationResult = await bookService.validateAddress({
          line1: address.addressLine1,
          line2: address.addressLine2,
          city: address.city,
          state: address.state,
          country: address.country,
          postalCode: address.zipCode,
        });
        if (validationResult.type == 'failed') {
          return setShippingAddressError({
            error: 'UNKNOWN',
            input: address,
            resolvedAddress: null,
          });
        }

        if (validationResult.warnings.length > 0) {
          return setShippingAddressError({
            error: validationResult.warnings[0].code,
            input: address,
            resolvedAddress: null,
          });
        }

        const resolvedAddress: BookShippingFormOutput = {
          recipientName: address.recipientName,
          addressLine1: validationResult.resolvedAddress.streetLines[0],
          addressLine2: validationResult.resolvedAddress.streetLines[1] ?? '',
          city: validationResult.resolvedAddress.city,
          state: validationResult.resolvedAddress.stateOrProvinceCode,
          country: address.country,
          zipCode: address.zipCode,
        };

        if (isEqual(address, resolvedAddress)) {
          return setStep('review');
        }

        setShippingAddressError({
          error: null,
          input: address,
          resolvedAddress,
        });
      });
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookService, shippingForm]);

  const handlePay = useCallback(async () => {
    const currentBook = bookQuery.data;
    if (!currentBook) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    const quantityFormValue = getFormValue(quantityForm);
    const shippingFormValue = getFormValue(shippingForm);
    if (quantityFormValue == null) {
      logger.warn('QUANTITY_FORM_NOT_SUBMITTED');
      return;
    }
    if (quantityFormValue.quantity > 0 && shippingFormValue == null) {
      logger.warn('SHIPPING_FORM_NOT_SUBMITTED');
      return;
    }

    try {
      const checkoutResponse = await bookService.checkoutBookOrder({
        type: 'new-order',
        bookTemplateId: bookId,
        quantity: quantityFormValue.quantity,
        credits: quantityFormValue.creditsApplied,
        ebook: quantityFormValue.ebook,
        shippingAddress: shippingFormValue,
        ...getMarketingCookies(),
      });
      window.open(checkoutResponse.paymentUrl, '_self');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookId, bookQuery.data, bookService, quantityForm, shippingForm]);

  // Shipping address error callbacks
  const handleSaveAddressErrorDialog = useCallback(
    (resolvedAddress: BookShippingFormOutput) => {
      setInputValues(shippingForm, resolvedAddress);
      setShippingAddressError(null);
      setStep('review');
    },
    [shippingForm],
  );

  const handleCloseAddressErrorDialog = useCallback(() => {
    setShippingAddressError(null);
  }, []);

  // Redirect to the previous step if the form is not valid
  useEffect(() => {
    if (isFormValid(quantityForm) == false) {
      navigate(getBookOrderQuantityPath(projectId, bookId));
    }
  }, [bookId, navigate, projectId, quantityForm]);

  return (
    <RMPage.Root>
      <BookOrderHeaderContainer
        projectId={projectId}
        bookId={bookId}
        step={BookOrderHeaderStep.Checkout}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <Content>
          <LeftPanel>
            <RMText type="serif" size="l" color="on-surface-primary">
              {step === 'review' ? 'Review and pay' : 'Enter your shipping information'}
            </RMText>

            <RMSpacer direction="column" spacing="2xl" />

            {step === 'shipping' && <BookShippingFormView form={shippingForm} />}
            {step === 'review' && (
              <BookOrderCheckoutReviewContainer
                projectId={projectId}
                bookId={bookId}
                quantityForm={quantityForm}
                shippingForm={shippingForm}
                reviewed={isReviewed}
                onReviewedChange={setIsReviewed}
                onEditShippingAddress={handleEdiShipping}
              />
            )}

            <RMSpacer direction="column" spacing="2xl" />

            <ButtonsWrapper>
              <RMButton background="neutral" autoLoading onClick={handlePrevious}>
                Previous
              </RMButton>

              {step === 'shipping' && (
                <RMButton
                  background="primary"
                  autoLoading
                  disabled={isShippingFormValid === false}
                  onClick={handleReview}
                >
                  Review
                </RMButton>
              )}
              {step === 'review' && (
                <RMButton background="primary" autoLoading disabled={isReviewed === false} onClick={handlePay}>
                  Continue to payment
                </RMButton>
              )}
            </ButtonsWrapper>
          </LeftPanel>
          <RightPanel>
            <BookOrderSummaryContainer projectId={projectId} bookId={bookId} quantityForm={quantityForm} />
          </RightPanel>
        </Content>

        {shippingAddressError != null && (
          <ShippingAddressErrorDialogContainer
            input={shippingAddressError.input}
            error={shippingAddressError.error}
            resolvedAddress={shippingAddressError.resolvedAddress}
            onSave={handleSaveAddressErrorDialog}
            onClose={handleCloseAddressErrorDialog}
          />
        )}
      </RMPage.Content>
    </RMPage.Root>
  );
}
