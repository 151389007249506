import { styled } from '@linaria/react';

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  border-radius: var(--radius-regular);
  border: 1px solid var(--border-strong);
`;

export const SuggestedAddressBox = styled(AddressBox)`
  border: 2px solid var(--primary);
`;
