import { RMInput } from '@/components/RMInput/RMInput';
import { RMSelect } from '@/components/RMSelect';
import { RMText } from '@/components/RMText/RMText';
import { InputContainer, InputController } from '@/modules/form/container';

import { BookShippingForm } from '../../book-shipping.form';

import { StyledBookOrderFormView } from './BookShippingFormView.styles';

const COUNTRY_OPTIONS = [
  {
    label: '',
    value: '',
    disabled: true,
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
];

export interface BookShippingFormViewProps {
  form: BookShippingForm;
}

export function BookShippingFormView({ form }: BookShippingFormViewProps) {
  return (
    <StyledBookOrderFormView>
      <InputContainer form={form} path="recipientName">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-recipient-name"
            label="Recipient name*"
            placeholder="First Last"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="addressLine1">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-line1"
            label="Address Line 1*"
            placeholder="65 Hansen Way"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="addressLine2">
        {(props) => (
          <RMInput id="book-order-shipping-address-line2" label="Address Line 2" placeholder="Apartment 4" {...props} />
        )}
      </InputContainer>
      <InputContainer form={form} path="city">
        {(props) => (
          <RMInput id="book-order-shipping-address-city" label="City/Town*" placeholder="Los Angeles" {...props} />
        )}
      </InputContainer>
      <InputContainer form={form} path="state">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-state"
            label="State/Region/Province*"
            placeholder="California"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="zipCode">
        {(props) => (
          <RMInput id="book-order-shipping-address-zip" label="Zip/Post code*" placeholder="93034" {...props} />
        )}
      </InputContainer>
      <InputController form={form} path="country">
        {({ value, error, onBlur, onChange }) => (
          <RMSelect
            id="book-order-shipping-address-country"
            label="Country*"
            options={COUNTRY_OPTIONS}
            value={value ?? ''}
            error={error}
            onChange={(value) => {
              onChange(value as 'United States' | 'Canada');
              onBlur();
            }}
          />
        )}
      </InputController>
      <RMText type="sans" size="s" color="on-surface-primary">
        You may ship your book to anywhere in the USA using economy shipping. Your book can take up to 3 weeks to print
        and ship. To ship to multiple addresses, you may check out again after placing your order.
      </RMText>
    </StyledBookOrderFormView>
  );
}
