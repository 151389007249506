import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import {
  BookOrderHeaderContainer,
  BookOrderHeaderStep,
} from '@/modules/book-builder/containers/BookOrderHeader.container';
import { BookOrderSummaryContainer } from '@/modules/book-builder/containers/BookOrderSummary.container';
import { BookQuantityFormViewContainer } from '@/modules/book-builder/containers/BookQuantityFormView.container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { getBookOrderFinalizePath, getBookOrderPreviewPath } from '@/modules/routing';
import { useBookQuery } from '@/services/api/book';
import { captureException } from '@/utils/captureException';

import { Buttons, Content, LeftPanel, RightPanel } from './BookOrderQuantityPage.styles';

export function BookOrderQuantityPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookId = params.bookId ?? '';

  // Services
  const navigate = useNavigate();

  // State
  const { quantityForm } = useBookOrderContext();
  const isQuantityFormValid = useIsFormValid(quantityForm);

  // Queries
  const bookQuery = useBookQuery(bookId);

  const handlePrevious = useCallback(() => {
    navigate(getBookOrderPreviewPath(projectId, bookId));
  }, [bookId, navigate, projectId]);

  const handleNext = useCallback(async () => {
    const currentBook = bookQuery.data;
    if (!currentBook) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    try {
      await submitForm(quantityForm, async () => {
        navigate(getBookOrderFinalizePath(projectId, bookId));
      });
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookId, bookQuery.data, quantityForm, navigate, projectId]);

  return (
    <RMPage.Root>
      <BookOrderHeaderContainer
        projectId={projectId}
        bookId={bookId}
        step={BookOrderHeaderStep.Quantity}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <Content>
          <LeftPanel>
            <BookQuantityFormViewContainer projectId={projectId} form={quantityForm} />
            <RMSpacer spacing="2xl" direction="column" />

            <Buttons>
              <RMButton background="neutral" autoLoading onClick={handlePrevious}>
                Previous
              </RMButton>
              <RMButton
                style={{ minWidth: '6.25rem' }}
                background="primary"
                autoLoading
                disabled={isQuantityFormValid == false}
                onClick={handleNext}
              >
                Next
              </RMButton>
            </Buttons>
          </LeftPanel>
          <RightPanel>
            <BookOrderSummaryContainer projectId={projectId} bookId={bookId} quantityForm={quantityForm} />
          </RightPanel>
        </Content>
      </RMPage.Content>
    </RMPage.Root>
  );
}
