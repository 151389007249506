import {
  ImageAssetAlternativeType,
  PdfDocumentAssetAlternativeType,
  PdfDocumentMetadata,
} from '@remento/types/alternative';
import { BookColor } from '@remento/types/book';

import { useInputValue } from '@/modules/form/input';
import { useAlternativeType, useAssetAlternativesQuery, useAssetImageUrl } from '@/services/api/asset';
import { useBookOrderQuery } from '@/services/api/book';
import { useProjectQuery } from '@/services/api/project';

import { BookQuantityForm } from '../book-quantity.form';
import { BookCoverPreview } from '../components/BookCoverPreview/BookCoverPreview';
import { BookOrderSummary } from '../components/BookOrderSummary/BookOrderSummary';

export interface BookReorderSummaryContainerProps {
  projectId: string;
  bookOrderId: string;
  quantityForm: BookQuantityForm;
}

const LARGE_BOOK_PAGE_THRESHOLD = 200;

export function BookReorderSummaryContainer({
  projectId,
  bookOrderId,
  quantityForm,
}: BookReorderSummaryContainerProps) {
  // Queries
  const projectQuery = useProjectQuery(projectId);
  const bookOrderQuery = useBookOrderQuery(bookOrderId);
  const coverUrl = useAssetImageUrl(bookOrderQuery.data?.coverAssetId ?? null, ImageAssetAlternativeType.SMALL);

  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookOrderQuery.data?.pdfAssetId);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    PdfDocumentAssetAlternativeType.PREVIEW,
  );
  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;

  // State
  const quantity = useInputValue(quantityForm, 'quantity') ?? 0;
  const creditsApplied = useInputValue(quantityForm, 'creditsApplied');
  const ebook = useInputValue(quantityForm, 'ebook');

  return (
    <BookOrderSummary
      quantity={quantity ?? 0}
      hasExtraFee={bookPageCount > LARGE_BOOK_PAGE_THRESHOLD}
      credits={creditsApplied ?? 0}
      ebook={ebook ?? false}
      ebookAlreadyPurchased={projectQuery.data?.credit?.ebook ?? false}
      BookCoverPreview={
        <BookCoverPreview
          title={bookOrderQuery.data?.title ?? null}
          subtitle={bookOrderQuery.data?.subtitle ?? null}
          color={bookOrderQuery.data?.color ?? BookColor.FOREST}
          photoUrl={coverUrl}
          padding={false}
          hideSideCover={quantity == 0 && ebook == true}
          ebookBanner={quantity == 0 && ebook == true}
        />
      }
    />
  );
}
