import { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { BookColor } from '@remento/types/book';
import { BookOrderStatus, EBookOrder, EBookOrderStatus, PhysicalBookOrder } from '@remento/types/book-order';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMPage } from '@/components/RMPage';
import { RMText } from '@/components/RMText/RMText';
import { BookCoverPreview } from '@/modules/book-builder/components/BookCoverPreview/BookCoverPreview';
import { BookPreviewScaler } from '@/modules/book-builder/components/BookPreviewScaler/BookPreviewScaler';
import { getBookReorderPreviewPath, getStoriesPath } from '@/modules/routing';
import { useAssetImageUrl } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useBookOrderQuery, usePollBookOrderUntilCompleted } from '@/services/api/book';

import {
  BookPreviewContainer,
  ButtonsWrapper,
  CardSeparator,
  Content,
  Header,
  OrderSummaryPreviewContainer,
  OrderSummaryTextWrapper,
  ShippingAddress,
  ShippingAddressWrapper,
  SummaryCard,
} from './BookOrderCompletePage.styles';

export function BookOrderCompletePage() {
  // Params
  const params = useParams();
  const [queryParams] = useSearchParams();
  const projectId = params.projectId ?? '';
  const ebookOrderId = queryParams.get('ebook-order-id');
  const bookOrderId = queryParams.get('book-order-id');

  // Services
  const navigate = useNavigate();
  const user = useUser();

  // Queries
  const ebookOrderQuery = useBookOrderQuery(ebookOrderId);
  const bookOrderQuery = useBookOrderQuery(bookOrderId);
  const ebookOrder = ebookOrderQuery.data == null ? null : (ebookOrderQuery.data as EBookOrder);
  const bookOrder = bookOrderQuery.data == null ? null : (bookOrderQuery.data as PhysicalBookOrder);

  const coverUrl = useAssetImageUrl((ebookOrder ?? bookOrder)?.coverAssetId ?? null, ImageAssetAlternativeType.SMALL);

  // Poll the book orders until they are completed.
  // We need this because the stripe webhook is not blocking, so it may take
  // some time to process the order in our backend.
  usePollBookOrderUntilCompleted(ebookOrderId, 1000);
  usePollBookOrderUntilCompleted(bookOrderId, 1000);

  const title = (ebookOrder ?? bookOrder)?.title ?? null;
  const subtitle = (ebookOrder ?? bookOrder)?.subtitle ?? null;
  const color = (ebookOrder ?? bookOrder)?.color ?? BookColor.FOREST;

  const ebook = ebookOrder != null && ebookOrder.status != EBookOrderStatus.CANCELED;
  const quantity = bookOrder != null && bookOrder.status != BookOrderStatus.CANCELED ? bookOrder.quantity : 0;

  const handleCreateAnotherOrder = useCallback(() => {
    if (bookOrder == null) {
      return;
    }

    navigate(getBookReorderPreviewPath(projectId, bookOrder.id));
  }, [navigate, bookOrder, projectId]);

  const handleViewStories = useCallback(() => {
    navigate(getStoriesPath(projectId));
  }, [navigate, projectId]);

  // Show the loader until the book orders are fetched and processed.
  if (ebookOrderId != null && (ebookOrder == null || ebookOrder.status == EBookOrderStatus.AWAITING_PAYMENT)) {
    return <PageLoader />;
  }
  if (bookOrderId != null && (bookOrder == null || bookOrder.status == BookOrderStatus.AWAITING_PAYMENT)) {
    return <PageLoader />;
  }

  return (
    <RMPage.Root>
      <RMPage.Content>
        <Content>
          <Header>
            <FontAwesomeIcon icon={faCheckCircle} size="3x" />
            <RMText type="serif" size="l" color="on-surface-primary">
              Your order is complete
            </RMText>
            <RMText type="sans" size="xxs" color="on-surface-primary" bold trackedOutCaps>
              ORDER #{bookOrder?.id ?? ebookOrder?.id}
            </RMText>
          </Header>
          <SummaryCard>
            <RMText
              style={{ letterSpacing: '1px' }}
              type="sans"
              size="xs"
              color="on-surface-primary"
              bold
              trackedOutCaps
            >
              ORDER SUMMARY
            </RMText>
            <OrderSummaryPreviewContainer>
              <BookPreviewContainer>
                <BookPreviewScaler height={110} center>
                  <BookCoverPreview
                    title={title}
                    subtitle={subtitle}
                    color={color}
                    photoUrl={coverUrl}
                    padding={false}
                    hideSideCover={ebook && quantity == 0}
                    ebookBanner={ebook && quantity == 0}
                  />
                </BookPreviewScaler>
              </BookPreviewContainer>
              <OrderSummaryTextWrapper>
                {quantity > 0 && (
                  <RMText type="sans" size="s" color="on-surface-primary">
                    {quantity} hardcover, color-printed book{quantity > 1 ? 's' : ''}
                  </RMText>
                )}
                {ebook && (
                  <RMText type="sans" size="s" color="on-surface-primary">
                    1 e-book
                  </RMText>
                )}
              </OrderSummaryTextWrapper>
            </OrderSummaryPreviewContainer>
            <CardSeparator />
            <ShippingAddressWrapper>
              <RMText type="sans" size="s" color="on-surface-primary">
                An email confirmation{ebook ? ' and your e-book ' : ' '}will be sent to{' '}
                <b>{user?.communicationChannels.email}</b>.
                {quantity > 0 && (
                  <>
                    {ebook && <br />}
                    {ebook && <br />}
                    {!ebook && ' '}
                    Your hardcover book will ship to the following address:
                  </>
                )}
              </RMText>
              {quantity > 0 && (
                <ShippingAddress>
                  <RMText type="sans" size="s" color="on-surface-primary" bold>
                    {bookOrder?.shipping.recipientName}
                  </RMText>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    {bookOrder?.shipping.addressLine1}
                    {bookOrder?.shipping?.addressLine2 && ' ' + bookOrder?.shipping?.addressLine2}
                  </RMText>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    {bookOrder?.shipping?.city}&nbsp; {bookOrder?.shipping?.state},&nbsp;
                    {bookOrder?.shipping?.zipCode}
                  </RMText>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    {bookOrder?.shipping.country}
                  </RMText>
                </ShippingAddress>
              )}
            </ShippingAddressWrapper>
            <CardSeparator />
            <ButtonsWrapper>
              {bookOrder != null && (
                <RMButton background="primary" fullWidth autoLoading onClick={handleCreateAnotherOrder}>
                  Order additional copies
                </RMButton>
              )}
              <RMButton
                background={bookOrder != null ? 'neutral' : 'primary'}
                fullWidth
                autoLoading
                onClick={handleViewStories}
              >
                View all stories
              </RMButton>
            </ButtonsWrapper>
          </SummaryCard>
        </Content>
      </RMPage.Content>
    </RMPage.Root>
  );
}
