import { useMemo } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingAddressValidationErrorCode } from '@remento/types/shipping';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { BookShippingFormOutput } from '../../book-shipping.form';

import { AddressBox, BodyContent, HeaderTitleWrapper, SuggestedAddressBox } from './ShippingAddressErrorDialog.styles';

const DEFAULT_ERROR_MESSAGE = 'We could not find this address in our database.';
const ERROR_MESSAGES: Partial<Record<ShippingAddressValidationErrorCode, string>> = {
  'STANDARDIZED.ADDRESS.NOTFOUND': DEFAULT_ERROR_MESSAGE,
  'INVALID.SUITE.NUMBER': 'Invalid or missing apartment or suite number.',
  'SUITE.NUMBER.REQUIRED': 'Invalid or missing apartment or suite number.',
  'PARTIAL.STREET.MATCH': 'The entered address is a street number range.',
  'MISSING.OR.AMBIGUOUS.DIRECTIONAL': 'Invalid or missing address directional (e.g., N, S, NW).',
  'INTERPOLATED.STREET.ADDRESS':
    'Unable to confirm exact street number for the entered street name. The address falls within a valid range for the street name.',
  'RRHC.CONVERSION': 'The address has been converted from a rural route.',
};

const CONTINUABLE_ERRORS = new Set<ShippingAddressValidationErrorCode>([
  'INTERPOLATED.STREET.ADDRESS',
  'RRHC.CONVERSION',
]);

export interface ShippingAddressErrorDialogProps {
  input: BookShippingFormOutput;
  error: ShippingAddressValidationErrorCode | 'UNKNOWN' | null;
  resolvedAddress: BookShippingFormOutput | null;
  onSave: (address: BookShippingFormOutput) => void;
  onEditAddress: () => void;
  onContactSupport: () => void;
  onClose: () => void;
}

export function ShippingAddressErrorDialog({
  input,
  error,
  resolvedAddress,
  onSave,
  onEditAddress,
  onContactSupport,
  onClose,
}: ShippingAddressErrorDialogProps) {
  const secondaryButtonLabel = useMemo(() => {
    if (error == null) {
      return 'Edit address';
    }

    if (error != null && error != 'UNKNOWN' && CONTINUABLE_ERRORS.has(error)) {
      return 'Continue with this address';
    }

    return 'Contact support';
  }, [error]);

  return (
    <RMDialog.Root open onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={
            error != null ? (
              <HeaderTitleWrapper>
                <FontAwesomeIcon icon={faTriangleExclamation} color="var(--danger)" />
                Shipping address error
              </HeaderTitleWrapper>
            ) : (
              'Confirm your address'
            )
          }
          message={
            error != null
              ? 'Our postal provider doesn’t recognize the address you entered.'
              : 'We could not verify the exact address you entered, but we found a close match. Please edit or confirm your address.'
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <BodyContent>
            {error != null && (
              <>
                <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                  Error message
                </RMText>
                <RMText type="sans" size="xs" color="on-danger-container">
                  {error != 'UNKNOWN' && ERROR_MESSAGES[error] != null ? ERROR_MESSAGES[error] : DEFAULT_ERROR_MESSAGE}
                </RMText>
                <RMSpacer direction="column" spacing="md" />
              </>
            )}
            <RMText type="sans" size="xs" color="on-surface-secondary" bold>
              You entered
            </RMText>
            <AddressBox>
              <RMText type="sans" size="s" color="on-surface-tertiary">
                {input.addressLine1}
                {input.addressLine2 && ' ' + input.addressLine2}
                <br />
                {input.city}&nbsp; {input.state},&nbsp;
                {input.zipCode}
                <br />
                {input.country}
              </RMText>
            </AddressBox>
            {resolvedAddress != null && (
              <>
                <RMSpacer direction="column" spacing="md" />
                <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                  Suggested address
                </RMText>
                <SuggestedAddressBox>
                  <RMText type="sans" size="s" color="on-surface-tertiary">
                    {resolvedAddress.addressLine1}
                    {resolvedAddress.addressLine2 && ' ' + resolvedAddress.addressLine2}
                    <br />
                    {resolvedAddress.city}&nbsp; {resolvedAddress.state},&nbsp;
                    {resolvedAddress.zipCode}
                    <br />
                    {resolvedAddress.country}
                  </RMText>
                </SuggestedAddressBox>
              </>
            )}
          </BodyContent>
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton
            autoLoading
            fullWidth
            onClick={() => {
              if (error == null) {
                return onEditAddress();
              }
              if (error != 'UNKNOWN' && CONTINUABLE_ERRORS.has(error)) {
                return onSave(input);
              }
              return onContactSupport();
            }}
          >
            {secondaryButtonLabel}
          </RMButton>
          <RMButton
            autoLoading
            fullWidth
            background="primary"
            onClick={() => {
              if (resolvedAddress != null) {
                onSave(resolvedAddress);
                return;
              }
              onEditAddress();
            }}
          >
            {resolvedAddress != null ? 'Save and continue' : 'Update address'}
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
