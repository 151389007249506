import { ReactNode } from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';

import { BookPreviewScaler } from '../BookPreviewScaler/BookPreviewScaler';

import {
  BookPreviewContainer,
  StyledBookOrderSummary,
  SubtotalWrapper,
  SummaryContent,
  SummaryTable,
  SummaryTableItem,
  SummaryTableItemLabelWrapper,
} from './BookOrderSummary.styles';

export interface BookOrderSummaryProps {
  quantity: number;
  hasExtraFee: boolean;
  credits: number;
  ebook: boolean;
  ebookAlreadyPurchased: boolean;
  BookCoverPreview: ReactNode;
}

const BOOK_PRICE = 69;
const EXTRA_FREE_PRICE = 30;
const EBOOK_PRICE = 49.99;

export function BookOrderSummary({
  quantity,
  hasExtraFee,
  credits,
  ebook,
  ebookAlreadyPurchased,
  BookCoverPreview,
}: BookOrderSummaryProps) {
  const bookCost = quantity * BOOK_PRICE;
  const extraFee = hasExtraFee ? quantity * EXTRA_FREE_PRICE : 0;
  const creditsDiscount = credits * BOOK_PRICE;
  const ebookCost = ebook && ebookAlreadyPurchased == false ? EBOOK_PRICE : 0;
  const subtotal = bookCost + extraFee - creditsDiscount + ebookCost;

  return (
    <StyledBookOrderSummary>
      <RMText style={{ letterSpacing: '1px' }} type="sans" size="xs" color="on-surface-primary" bold>
        ORDER SUMMARY
      </RMText>
      <SummaryContent>
        <BookPreviewContainer>
          <BookPreviewScaler height={200}>{BookCoverPreview}</BookPreviewScaler>
        </BookPreviewContainer>
        <SummaryTable>
          {quantity > 0 && (
            <SummaryTableItem>
              <RMTooltip message="It costs $69 per book">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Hardcover books ({quantity} {quantity === 1 ? 'item' : 'items'})
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${bookCost.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {quantity > 0 && hasExtraFee === true && (
            <SummaryTableItem>
              <RMTooltip message="Books between 201-380 pages incur a $30 fee">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Extra fee
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${extraFee.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {ebook && (
            <SummaryTableItem>
              <RMTooltip message="Your e-book will be delivered via email">
                <SummaryTableItemLabelWrapper>
                  <RMText type="sans" size="s" color="on-surface-primary">
                    E-book
                  </RMText>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </SummaryTableItemLabelWrapper>
              </RMTooltip>
              <RMText type="sans" size="s" color="on-surface-primary">
                ${(ebookAlreadyPurchased ? 0 : EBOOK_PRICE).toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
          {credits > 0 && (
            <SummaryTableItem>
              <SummaryTableItemLabelWrapper>
                <RMText type="sans" size="s" color="on-surface-primary">
                  Credits applied
                </RMText>
              </SummaryTableItemLabelWrapper>
              <RMText type="sans" size="s" color="on-surface-primary">
                -${creditsDiscount.toFixed(2)}
              </RMText>
            </SummaryTableItem>
          )}
        </SummaryTable>

        <SubtotalWrapper>
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            Subtotal*
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary" bold>
            ${subtotal.toFixed(2)}
          </RMText>
        </SubtotalWrapper>

        <RMText type="sans" size="xs" color="on-surface-primary">
          *Includes US shipping. Tax calculated at checkout.
        </RMText>
      </SummaryContent>
    </StyledBookOrderSummary>
  );
}
