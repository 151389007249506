import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2xl);
  max-width: 517px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
  color: var(--color-green-30);
`;

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
  background-color: var(--surface-bright);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-2);
  border-radius: var(--radius-regular);
`;

export const CardSeparator = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
`;

export const ShippingAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const ShippingAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
`;

export const OrderSummaryPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

export const OrderSummaryTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const BookPreviewContainer = styled.div`
  position: relative;
  height: calc(110px + calc(var(--spacing-md) * 2));
  width: calc(113.45px + calc(var(--spacing-md) * 2));
  padding: var(--spacing-md);
  background-color: var(--surface-dim);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-sm);
`;
