import { styled } from '@linaria/react';

export const StyledBookOrderCheckoutReview = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
`;

export const ReviewSummaryListRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const ReviewSummaryListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const SummaryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SummaryEditButton = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--primary);
  cursor: pointer;
`;

export const SummarySeparator = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
`;
